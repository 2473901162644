<template>
  <div class="MERCH">
    <div class="merch-card">
      <div class="content">
        <div class="content-list">
          <div class="lable">自动更新资料库</div>
          <div class="mess">
            <el-switch
              v-model="formApi.status"
              active-color="#06b7ae"
              inactive-color="#ff4949"
            >
            </el-switch>
          </div>
        </div>
        <div class="content-list">
          <div class="lable">更新周期</div>
          <div class="mess">
            <el-select
              v-model="formApi.syncCycle"
              placeholder="请选择"
              disabled
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content-list">
          <div class="lable">更新数据</div>
          <div class="mess">
            <el-radio-group v-model="formApi.isAll">
              <el-radio label="1" disabled>仅有更新的数据</el-radio>
              <el-radio label="2" disabled>全量数据</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="content-list">
          <div class="lable">更新内容</div>
          <div class="mess">
            <el-radio-group v-model="formApi.radio">
              <el-radio label="1">全部商品信息</el-radio>
              <el-radio label="2">指定商品信息</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="content-list" v-if="formApi.radio == 2">
          <div class="lable">请选择更新信息</div>
          <div class="mess">
            <el-checkbox-group v-model="formApi.type">
              <el-checkbox label="1">商品图片</el-checkbox>
              <el-checkbox label="2">商品说明书</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="operation">
          <div class="go-back" @click="goBack()">返回</div>
          <div class="update" @click="update()">更新设置</div>
        </div>
      </div>
    </div>
    <el-dialog
      title="放弃更新提醒"
      :visible.sync="dialogVisibleAbandon"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="dialog-mess">
        您已修改当前资料库更新设置内容，点击确定代表放弃本次更新操作，所设置内容不会生效。确定放弃本次更新吗？
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm"> 确定 </el-button>
        <el-button @click="dialogVisibleAbandon = false"> 取消 </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="更新设置成功"
      :visible.sync="dialogVisibleSucceed"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="dialog-mess"> 资料库更新设置更新成功。 </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleSucceed = false" type="primary">
          知道了
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      formApi: {
        status: true,
        syncCycle: 2,
        isAll: "2",
        radio: "1",
        type: [],
      },
      reData: {},
      options: [
        {
          value: 1,
          label: "每日（每日00:00:00更新）",
        },
        {
          value: 2,
          label: "每周（每周一00:00:00更新）",
        },
        {
          value: 3,
          label: "每月（每月1日00:00:00更新）",
        },
        {
          value: 4,
          label: "每季（每年1、4、7、10月1日00:00:00更新）",
        },
      ],
      dialogVisibleAbandon: false,
      dialogVisibleSucceed: false,
    };
  },

  created() {
    this.getconfigSetDetailApi();
  },
  methods: {
    ...mapActions(["getconfigSetDetail", "postconfigSetUpdate"]),
    // 获取详情
    async getconfigSetDetailApi() {
      let res = await this.getconfigSetDetail();
      if (res.code == "000000") {
        if (res.content.status == 1) {
          res.content.status = true;
        } else {
          res.content.status = false;
        }
        if (res.content.syncContent == 0) {
          res.content.radio = "1";
          res.content.type = [];
        } else if (res.content.syncContent == 1) {
          res.content.radio = "2";
          res.content.type = ["1", "2"];
        } else if (res.content.syncContent == 2) {
          res.content.radio = "2";
          res.content.type = ["1"];
        } else if (res.content.syncContent == 3) {
          res.content.radio = "2";
          res.content.type = ["2"];
        }
        console.log(res);
        res.content.isAll = "2";
        this.formApi = res.content;
        this.reData = JSON.parse(JSON.stringify(res.content));
      }
    },
    // 确认
    confirm() {
      this.dialogVisibleAbandon = false;
      this.$router.push({
        path: "/goodsLibrary",
      });
    },
    // 返回
    goBack() {
      if (this.formApi.radio == "1") {
        this.formApi.syncContent = 0;
      } else if (this.formApi.radio == "2") {
        if (this.formApi.type.length == 2) {
          this.formApi.syncContent = 1;
        } else {
          if (this.formApi.type[0] == "1") {
            this.formApi.syncContent = 2;
          }
          if (this.formApi.type[0] == "2") {
            this.formApi.syncContent = 3;
          }
        }
      }
      if (
        this.formApi.status != this.reData.status ||
        this.formApi.radio != this.reData.radio ||
        this.formApi.syncContent != this.reData.syncContent
      ) {
        this.dialogVisibleAbandon = true;
        return;
      } else {
        this.confirm();
      }
      // this.dialogVisibleAbandon = true;
    },
    // 更新
    async update() {
      if (this.formApi.radio == "2" && this.formApi.type.length == 0) {
        this.$message({
          message: "请至少选择一个更新信息",
          type: "warning",
        });
        return;
      }
      let syncContent = null;
      console.log(this.formApi);
      if (this.formApi.radio == "1") {
        syncContent = 0;
      } else if (this.formApi.radio == "2") {
        if (this.formApi.type.length == 2) {
          syncContent = 1;
        } else {
          if (this.formApi.type[0] == "1") {
            syncContent = 2;
          }
          if (this.formApi.type[0] == "2") {
            syncContent = 3;
          }
        }
      }
      let status = 0;
      if (this.formApi.status) {
        status = 1;
      }
      let params = {
        id: this.formApi.id,
        syncCycle: this.formApi.syncCycle,
        syncContent,
        status,
        syncTime: "0",
      };
      let res = await this.postconfigSetUpdate(params);
      if (res.code == "000000") {
        this.dialogVisibleSucceed = true;
        this.getconfigSetDetailApi();
      }
    },
  },
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    display: flex;
    justify-content: center;
  }
}
.content {
  padding-top: 20px;
  .content-list {
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    .lable {
      width: 150px;
      font-size: 14px;
      padding: 20px 0;
      color: #333;
    }
    .mess {
      width: 300px;
      // display: flex;
    }
  }
}
.operation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  > div {
    width: 88px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  .update {
    background-color: #06b7ae;
    color: #fff;
    margin-left: 10px;
    text-align: center;
  }
}
::v-deep .el-checkbox__label {
  color: #333;
  width: 110px;
}
::v-deep .el-radio__label {
  color: #333;
  width: 110px;
  display: inline-block;
}
::v-deep .el-input__inner {
  height: 32px !important;
}
::v-deep .el-input {
  height: 32px;
  width: 280px;
}
::v-deep .el-input__icon {
  height: 40px;
  position: relative;
  top: -3px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
  line-height: 20px;
}
</style>
